<template>
<opm-page :show-modal="showModal" @hide="showModal = false" :wx-share="wxShare">
  <ion-content>
    <swiper :slides-per-view="1" :loop="true" :autoplay="{ delay: 3000, disableOnInteraction: false}" :pagination="{ 'dynamicBullets': true }">
      <swiper-slide v-for="(photo, ind) in project.galleries" :key="ind">
        <ion-img :src="project.imageHost + photo"/>
      </swiper-slide>
    </swiper>
    <div class="ion-padding">
      <div class="flex-between">
        <div>
          <span class="tag" :class="ind === 0 ? 'orange' : ''" v-for="(tag, ind) in project.tags.split(',')" :key="ind">
            {{ tag }}
          </span>
        </div>
        <div>
          <ion-label style="color:grey;font-size:16px" @click="showModal = true">
            <ion-icon slot="start" :icon="starOutline"></ion-icon>
          </ion-label>
        </div>
      </div>
      <h2 style="font-weight: 600">{{ project.name }}</h2>
      <div>
        <div style="color: red;display: inline-block">
          <span style="font-size: 12px">￥</span><span style="font-size:20px">{{ project.priceStart }}-{{ project.priceEnd }}</span><span style="font-size: 12px">万</span>
        </div>
        <div style="color: #8F93A0;margin-left: 5px; display: inline-block;font-size:12px">$ {{ project.secondPriceStart }}-{{ project.secondPriceEnd }}万澳元</div>
      </div>
      <div class="flex-between" style="margin-top: 10px;font-size: 14px; padding:10px;color:#38498C;background: linear-gradient(95deg, #FBFBFD 0%, #EBEEFA 100%);border-radius: 4px;">
        <span>{{ project.parentSectionName }}-{{ project.sectionName }} | {{ project.deliveryTime }} 交房</span>
        <img src="/assets/broad-icon.png" width="19" />
      </div>
      <div style="margin-top: 16px;" class="flex-between">
        <div>
          <div class="room-title">{{ project.houseType }}</div>
          <div class="room-subtitle">户型</div>
        </div>
        <div>
          <div class="room-title">{{ project.areaRange }}㎡</div>
          <div class="room-subtitle">面积</div>
        </div>
        <div>
          <div class="room-title">总高{{ project.floorNumber }}层</div>
          <div class="room-subtitle">楼层</div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <div><h4>地理位置</h4></div>
        <div style="position: relative">
          <img :src="project.imageHost + project.positionPicture" />
          <div style="position: absolute;bottom: 0;left: 0px;padding: 10px;width:100%;background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.81) 35%, #FFFFFF 100%);">
            <div style="">
              <div style="font-size: 18px">{{ project.sectionName }}</div>
              <span style="font-size: 13px; color: #34394D">{{ project.address }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <div><h4>配套设施</h4></div>
        <p>{{ project.facilitiesDesc }}</p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>户型介绍</h4></div>
        <p v-html="project.houseTypeDetail"></p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>投资热点</h4></div>
        <p>{{ project.investmentDesc }}</p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>人口就业</h4></div>
        <p>{{ project.employeeDesc }}</p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>城市规划</h4></div>
        <p>{{ project.cityPlanningDesc }}</p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>交通</h4></div>
        <p>{{ project.trafficDesc }}</p>
      </div>
      <div style="margin-top: 30px">
        <div><h4>教育</h4></div>
        <p>{{ project.educationDesc }}</p>
      </div>
    </div>
  </ion-content>
  <ion-item lines="none">
    <div slot="start">
      <div style="font-size: 10px">售价范围</div>
      <div style="color: red; font-size: 20px">￥{{ project.priceStart }}-{{ project.priceEnd }}万</div>
      <ion-label style="font-size: 10px; color: #8F93A0">$ {{ project.secondPriceStart }}-{{ project.secondPriceEnd }}万澳元</ion-label>
    </div>
    <div slot="end">
      <ion-button size="large" style="color: #fff" @click="showModal = true">在线咨询</ion-button>
    </div>
  </ion-item>
</opm-page>
</template>

<script type="ts">
import OpmPage from "@/components/OpmPage";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { IonContent, IonItem, IonLabel, IonButton, IonImg, IonIcon  } from '@ionic/vue';
import { starOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import 'swiper/swiper-bundle.css';
import {useRoute} from "vue-router";

SwiperCore.use([Pagination, Autoplay])
export default defineComponent({
  name: "Project",
  data() {
    return {
      starOutline,
      route: useRoute(),
      project: { tags: ''},
      showModal: false,
      wxShare: {}
    }
  },
  mounted() {
    this.$axios.get(`project/detail?projectId=${this.route.params.id}`).then(resp => {
      this.project = resp.result
      document.title = '【鱼掌门App】' +  this.project.name
      const imgUrl = this.project.imageHost + this.project.galleries[0]
      this.wxShare = { title: this.project.name, desc: this.project.facilitiesDesc || document.title, link: location.href, imgUrl: imgUrl }
    })
  },
  methods: {

  },
  components: {
    OpmPage,
    IonContent,
    IonItem,
    IonLabel,
    IonButton,
    IonImg,
    Swiper,
    SwiperSlide,
    IonIcon
  }
})
</script>

<style scoped>
.tag {
  margin-right: 5px;padding: 3px;font-size:10px;
  border: 1px solid #dedede;
}
.tag.orange {
  border: 0;
  padding: 4px;
  color: #fff; background: #FF640A;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.room-title{
  font-size: 18px;
  color: #34394D;
}
.room-subtitle {
  font-size: 12px; color: #8F93A0
}
</style>